html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro', sans-serif !important;
}

body, input, button, select, textarea {
  font-family: 'Source Sans Pro', sans-serif !important;
}
.container {
  flex: 1;
}

footer {
  background-color: lightgrey;
  text-align: center;
  margin-top: auto;
  width: 100%;
  position: relative;
  bottom: 0;
  height: 4.1vw;
  z-index: 1000;
}
